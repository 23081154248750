/* eslint-disable prefer-destructuring */

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { Sidebar, LatLongMap } from '@/components';
import { countryArray, provinceArray, isNullOrWhiteSpace } from '@/utils';
import isServer from '@/utils/isServer';

if (!isServer) {
  const { required, email } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');
  var VueTagsInput = require('@johmun/vue-tags-input').default;
  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('url', {
    // eslint-disable-next-line no-unused-vars
    message: 'Url kan geen "/", "?" of "." bevatten.',
    validate: (value) => !value.includes('?') && !value.includes('/') && !value.includes('.'),
  });

  extend('coordinate', {
    // eslint-disable-next-line no-unused-vars
    message: 'Geef een geldig coördinaat in (Bv: xx.xxxxxx)',
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      const strongRegex = new RegExp(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/);
      return strongRegex.test(value);
    },
  });

  // eslint-disable-next-line no-var
  var { VueEditor } = require('vue2-editor/dist/vue2-editor.core');
  var ImageUpload = require('vue-image-crop-upload').default;
}

export default {
  metaInfo: {
    title: 'Group formpage',
  },
  name: 'GroupForm',
  components: {
    [Sidebar.name]: Sidebar,
    VueEditor,
    LatLongMap,
    ImageUpload,
    VueTagsInput,
  },
  props: {},
  data: () => ({
    editMode: false,
    currentTag: '',
    tags: [],
    group: {
      name: '',
      abbreviation: '',
      contactPerson: '',
      address_Street: '',
      address_Nr: '',
      address_Postcode: '',
      address_City: '',
      address_Country: '',
      address_Province: '',
      contactEmail: '',
      contactPhone: '',
      groupUrl: '',
      logo: '',
      groupDisciplines: [],
      lat: null,
      long: null,
      aboutUs: '',
      image: '',
      coverImage: '',
      tags: [],
    },
    show: false,
    showCover: false,
    imageObj: '',
    coverImageObj: '',
    error: false,
    errorCover: false,
    loadCoordinateMap: false,
    existingMarker: null,
    disciplineErrorMessage: '',
    mapErrorMessage: '',
    editorToolbar: [['bold', 'link']],
    provinceArray: provinceArray.map((p) => ({ name: p })),
    countryArray: countryArray.map((c) => ({ name: c })),
    selectedProvince: { name: 'West - Vlaanderen' },
    selectedCountry: { name: 'België' },
    url: process.env.VUE_APP_URL_MANUAL,
  }),
  computed: {
    ...mapGetters('tag', {
      tagsList: 'getTagList',
    }),
    filteredItems() {
      return this.tagsList.filter(
        (i) => i.text.toLowerCase().indexOf(this.currentTag.toLowerCase()) !== -1,
      );
    },
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    ...mapGetters('group', {
      groupId: 'getGroupId',
      editGroup: 'getGroup',
    }),
    formattedDisciplineList() {
      const formattedDisciplineList = [];

      this.disciplineList.forEach((discipline) => {
        if (discipline.level === 0) {
          const d = _.clone(discipline);
          formattedDisciplineList.push(d);
        }
      });
      return formattedDisciplineList;
    },
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('tag', {
      initialiseTags: 'FETCH_DATA_TAG_LIST',
    }),
    ...mapActions('group', {
      createAction: 'CREATE_GROUP',
      editAction: 'EDIT_GROUP',
      initialiseGroup: 'FETCH_DATA_EDIT_GROUP',
    }),
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    tagsChanged(tags) {
      this.tags = tags.map((t) => ({ text: t.text }));
      this.group.tags = this.tags;
    },
    inputChanged(event, disciplineId) {
      if (event.srcElement.checked) {
        this.addDisciplineId(disciplineId);
      } else {
        this.removeDisciplineId(disciplineId);
      }
    },
    cropSuccess(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.group.image = file;
          this.group.hasPicture = true;
        })
        .then(() => {
          this.createImage(this.group.image);
          this.toggleShow();
        });
    },
    cropSuccessCover(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.group.coverImage = file;
          this.group.hasCoverPicture = true;
        })
        .then(() => {
          this.createImageCover(this.group.coverImage);
          this.toggleShowCover();
        });
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createImageCover(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.coverImageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    toggleShow() {
      this.show = !this.show;
    },
    toggleShowCover() {
      this.showCover = !this.showCover;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else {
          if (isNullOrWhiteSpace(this.group.address_Country) || isNullOrWhiteSpace(this.group.address_Province)) {
            this.group.address_Province = this.selectedProvince.name;
            this.group.address_Country = this.selectedCountry.name;
          }
          if (this.group.groupDisciplines.length <= 0) {
            this.disciplineError = 'Minstens 1 categorie vereist.';
          } else if (this.group.lat == null) {
            this.mapErrorMessage = 'Gelieve een locatie aan te duiden op de map.';
          } else if (this.editMode) {
            this.updateGroup();
          } else {
            this.createNewGroup();
          }
        }
      });
    },
    cancel() {
      this.$router.push({ name: 'group-detail', params: { groupurl: this.group.groupUrl } });
    },

    addDisciplineId(id) {
      this.group.groupDisciplines.push(id);
      this.errorCheckGroupDisciplines();
    },
    removeDisciplineId(id) {
      const index = this.group.groupDisciplines.indexOf(id);
      this.group.groupDisciplines.splice(index, 1);
      this.errorCheckGroupDisciplines();
    },
    isSuperAdmin() {
      return !!(this.$isSuperAdmin);
    },
    isAdmin() {
      return !!(this.$isAdmin || this.group.currentUserIsAdmin);
    },
    fillGroupDisciplineList() {
      this.group.groupDisciplines.forEach((groupDiscipline) => {
        this.formattedDisciplineList.find((discipline) => discipline.id === groupDiscipline).checked = true;
      });
    },
    isAllowed() {
      if (this.editMode) {
        return this.isSuperAdmin() || this.isAdmin();
      }
      return true;
    },
    createNewGroup() {
      this.createAction(this.group)
        .then(() => {
          this.$router.push({ name: 'group-detail', params: { groupurl: this.group.groupUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    updateGroup() {
      this.editAction(this.group)
        .then(() => {
          this.$router.push({ name: 'group-detail', params: { groupurl: this.group.groupUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    errorCheckGroupDisciplines() {
      if (this.group.groupDisciplines.length <= 0) {
        this.disciplineErrorMessage = 'Minstens 1 categorie vereist.';
      } else {
        this.disciplineErrorMessage = '';
      }
    },
    newLatLong(newLatLong) {
      [this.group.lat, this.group.long] = newLatLong;
    },

    updateProvinceId(selectedProvince) {
      if (selectedProvince) {
        this.group.address_Province = selectedProvince.name;
      }
    },

    updateCountryId(selectedCountry) {
      if (selectedCountry) {
        this.group.address_Country = selectedCountry.name;
      }
    },
    setGroupUrl() {
      if (!this.group.groupUrl) {
        this.$set(this.group, 'groupUrl', this.group.name);
      }
    },
  },
  created() {
    this.initialiseTags();
    if (this.$route.params.groupurl) {
      const paramGroupUrl = this.$route.params.groupurl;
      this.editMode = true;

      this.initialiseDisciplineList().then(() => {
        this.initialiseGroup(paramGroupUrl).then(() => {
          this.imageObj = _.cloneDeep(this.editGroup.profilePicture);
          this.coverImageObj = _.cloneDeep(this.editGroup.coverPicture);
          this.group = _.cloneDeep(this.editGroup);

          this.selectedProvince.name = this.group.address_Province;
          this.selectedCountry.name = this.group.address_Country;
          if (!this.isAdmin()) {
            this.$router.push({ name: '404' });
          }

          this.fillGroupDisciplineList();

          this.existingMarker = {
            id: 1,
            latLong: [this.group.lat, this.group.long],
          };
          this.loadCoordinateMap = true;
          // Set existing tags:
          this.tags = this.group.groupTags.map((t) => ({ text: t.name }));
          this.group.tags = this.tags; // Update/Create model requires this format.
        });
      });
    } else this.initialiseDisciplineList();
  },
};
